<template>
  <b-card>
    <!-- form -->

    <validation-observer
      ref="refFormObserver"
      #default="{ invalid }"
    >
      <b-form @submit.prevent="submitChangePassword">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="old-password">{{ $t('messagesList.oldPassword') }}</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                vid="old-password"
                rules="required|min:6"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="old-password"
                    v-model="oldPassword"
                    autocomplete="current-password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="oldPasswordFieldType"
                    name="old-password"
                    placeholder="********"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconOld"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>

        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="new-password">{{ $t('messagesList.newPassword') }}</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="New Password"
                vid="new-password"
                rules="required|min:6"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="new-password"
                    v-model="newPassword"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="newPasswordFieldType"
                    name="new-password"
                    placeholder="********"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="re-password">{{ $t('messagesList.retypePassword') }}</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="confirmation"
                vid="re-password"
                rules="required|min:6|confirmed:new-password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="re-password"
                    v-model="rePassword"
                    autocomplete="off"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="rePasswordFieldType"
                    name="re-password"
                    placeholder="********"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconRetype"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="invalid"
              type="submit"
            >
              {{ $t('saveChanges') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click="reset"
            >
              {{ $t('reset') }}
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { computed, ref } from '@vue/composition-api'

import { getUserData } from '@/api/auth/utils'
import { useRouter } from '@/@core/utils/utils'
import useJwt from '@/api/auth/jwt/useJwt'
import { apiEmployee } from '@/api'

import formValidation from '@core/comp-functions/forms/form-validation'

import useToast from '@useToast'
import { required, confirmed } from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    const oldPassword = ref(null)
    const newPassword = ref(null)
    const rePassword = ref(null)
    const oldPasswordFieldType = ref('password')
    const newPasswordFieldType = ref('password')
    const rePasswordFieldType = ref('password')

    const { toastSuccess, toastError } = useToast()
    const { router } = useRouter()

    const { refFormObserver } = formValidation()

    // Computed
    const passwordToggleIconOld = computed(() => (oldPasswordFieldType.value === 'password' ? 'EyeOffIcon' : 'EyeIcon'))
    const passwordToggleIconNew = computed(() => (newPasswordFieldType.value === 'password' ? 'EyeOffIcon' : 'EyeIcon'))
    const passwordToggleIconRetype = computed(() => (rePasswordFieldType.value === 'password' ? 'EyeOffIcon' : 'EyeIcon'))

    // Methods
    function togglePasswordOld() {
      oldPasswordFieldType.value = oldPasswordFieldType.value === 'password' ? 'text' : 'password'
    }
    function togglePasswordNew() {
      newPasswordFieldType.value = newPasswordFieldType.value === 'password' ? 'text' : 'password'
    }
    function togglePasswordRetype() {
      rePasswordFieldType.value = rePasswordFieldType.value === 'password' ? 'text' : 'password'
    }
    function reset() {
      oldPassword.value = ''
      newPassword.value = ''
      rePassword.value = ''
    }
    function submitChangePassword() {
      const { employeeData } = getUserData()
      refFormObserver.value.validate()
        .then(success => {
          if (success && newPassword.value === rePassword.value) {
            this.$bvModal.show('modal-api-loading')
            apiEmployee
              .changePassword(employeeData.id, {
                oldPassword: oldPassword.value,
                newPassword: newPassword.value,
              })
              .then(() => {
                useJwt.logout().then(() => {
                  useJwt.clearStorage()

                  // Redirect to login page
                  router.replace({ name: 'auth-login' })
                })
                toastSuccess({
                  title: 'messagesList.success',
                  content: 'messagesList.auth.passwordChanged',
                })
              })
              .catch(err => {
                toastError({
                  title: 'messagesList.error',
                  content: `messagesList.auth.${err.response.data.message}`,
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
        .catch(err => {
          console.error({ err })
        })
    }

    return {
      // data
      oldPassword,
      newPassword,
      rePassword,
      oldPasswordFieldType,
      newPasswordFieldType,
      rePasswordFieldType,

      // validation
      required,
      confirmed,
      refFormObserver,

      // methods
      togglePasswordOld,
      togglePasswordNew,
      togglePasswordRetype,
      reset,
      submitChangePassword,

      // computed
      passwordToggleIconOld,
      passwordToggleIconNew,
      passwordToggleIconRetype,
    }
  },
}
</script>
