var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitChangePassword($event);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('label', {
          attrs: {
            "for": "old-password"
          }
        }, [_vm._v(_vm._s(_vm.$t('messagesList.oldPassword')))])]), _c('validation-provider', {
          attrs: {
            "name": "Old Password",
            "vid": "old-password",
            "rules": "required|min:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "old-password",
                  "autocomplete": "current-password",
                  "state": errors.length > 0 ? false : null,
                  "type": _vm.oldPasswordFieldType,
                  "name": "old-password",
                  "placeholder": "********"
                },
                model: {
                  value: _vm.oldPassword,
                  callback: function callback($$v) {
                    _vm.oldPassword = $$v;
                  },
                  expression: "oldPassword"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconOld
                },
                on: {
                  "click": _vm.togglePasswordOld
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('label', {
          attrs: {
            "for": "new-password"
          }
        }, [_vm._v(_vm._s(_vm.$t('messagesList.newPassword')))])]), _c('validation-provider', {
          attrs: {
            "name": "New Password",
            "vid": "new-password",
            "rules": "required|min:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "new-password",
                  "autocomplete": "off",
                  "state": errors.length > 0 ? false : null,
                  "type": _vm.newPasswordFieldType,
                  "name": "new-password",
                  "placeholder": "********"
                },
                model: {
                  value: _vm.newPassword,
                  callback: function callback($$v) {
                    _vm.newPassword = $$v;
                  },
                  expression: "newPassword"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconNew
                },
                on: {
                  "click": _vm.togglePasswordNew
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('label', {
          attrs: {
            "for": "re-password"
          }
        }, [_vm._v(_vm._s(_vm.$t('messagesList.retypePassword')))])]), _c('validation-provider', {
          attrs: {
            "name": "confirmation",
            "vid": "re-password",
            "rules": "required|min:6|confirmed:new-password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "re-password",
                  "autocomplete": "off",
                  "state": errors.length > 0 ? false : null,
                  "type": _vm.rePasswordFieldType,
                  "name": "re-password",
                  "placeholder": "********"
                },
                model: {
                  value: _vm.rePassword,
                  callback: function callback($$v) {
                    _vm.rePassword = $$v;
                  },
                  expression: "rePassword"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIconRetype
                },
                on: {
                  "click": _vm.togglePasswordRetype
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-1 mr-1",
          attrs: {
            "variant": "primary",
            "disabled": invalid,
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('saveChanges')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-1",
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          },
          on: {
            "click": _vm.reset
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reset')) + " ")])], 1)], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }